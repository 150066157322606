@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900');
@import '~@fancyapps/fancybox/dist/jquery.fancybox.css';

@import 'reset';
@import 'grid';
@import 'mixins';
@import 'fonts';

body {
    font-family: 'Poppins';
    font-size: 14px;
    color: #5f5f5f;
    line-height: 1.75;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}

.container {
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1200px;
}

h1 {
    font-size: 22px;
    font-weight: 500;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 10px;
}

h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

h3 {
    font-size: 16px;
    font-weight: 500;
}

h4 {
    font-weight: 600;
}

ul {
    margin-bottom: 15px;
    list-style: inside disc;
}

p {
    margin-bottom: 15px;
}

hr {
    border: 0;
    border-top: 1px solid #f1f1f1;
    margin: 15px 0;
}

strong {
    font-weight: 500;
}

a {
    color: inherit;
}

.alert {
    background: #fdc038;
    padding: 15px;
    margin-bottom: 15px;
    color: #000;
    font-weight: 500;
}

.notice {
    color: red;
    font-weight: 600;
}

small {
    font-size: 80%;
}

.no-margin {
    margin: 0;
}

.visible {
    display: block !important;
}

.red {
    color: red;
}

//-----------------------------------
// Site header
//-----------------------------------
.site-header {
    background: #000 url(https://www.ukregplates.co.uk/images/site-header.png?bc7ea160967c9625130f924a20d10138);
    padding: 8px 0;
    
    @media (min-width: 992px) {
        padding: 15px 0;
    }
    
    .primary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    a.menu-icon {
        display: block;
        
        @media (min-width: 992px) {
            display: none;
        }
        
        span {
            width: 20px;
            height: 2px;
            background: #fff;
            display: block;
            
            + span {
                margin-top: 4px;
            }
        }
    }
    
    img.logo {
        height: 30px;
        display: block;
        
        @media (min-width: 992px) {
            height: 45px;
        }
    }
    
    a.cart-icon {        
        svg {
            display: block;
            height: 20px;
        }
    }
    
    nav {
        font-family: 'Poppins', 'roboto-slab';
        font-weight: 600;
        font-size: 16px;
        
        @media (max-width: 991px) {
            display: none;
        }
    }
    
    ul {
        display: flex;
        margin: 0;
        list-style: none;
    }
    
    li + li {
        margin-left: 20px;
    }
    
    a {
        color: #fff;
        text-decoration: none;
    }
    
    .mobile-menu {
        background: #000;
        width: 100%; height: 100%;
        top: 0; left: 0;
        position: fixed;
        z-index: 999;
        padding: 30px 15px;
        
        font-size: 22px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;
        
        &.visible {
            opacity: 1;
            visibility: visible;
        }
        
        .close {
            position: absolute;
            background: url(../images/menu-close.png);
            background-size: cover;
            width: 30px; height: 30px;
            text-indent: -10000px;
            top: 30px; right: 30px;
        }
        
        ul {
            list-style: inside none;
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
            padding: 50px 0;
        }
        
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}

.facts {
    background: linear-gradient(#fbbf37,#fba536);
    position: relative;
    width: 100%;
    height: 50px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    z-index: 10;
    
    @media (min-width: 992px) {
        height: auto;
    }
    
    .items {
        height: 50px;
        
        @media (min-width: 992px) {
            height: auto;
            display: flex;
            justify-content: space-between;
        }
    }
    
    .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        overflow: hidden;
        position: absolute;
        top: 0; left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 1s ease;
        
        @media (min-width: 992px) {
            position: static;
            opacity: 1;
            visibility: visible;
            height: auto;
            padding: 15px 0;
            transition: none;
        }
        
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
    
    p {
        color: #fff;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 800;
        line-height: 1;
        margin-left: 10px;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        padding-left: 10px;
        margin-bottom: 0;
    }
    
    span {
        display: block;
    }
    
    span + span {
        margin-top: 3px;
        font-size: 12px;
        font-weight: 400;
    }
    
    svg {
        height: 20px;
        color: #000;
    }
}

//-----------------------------------
// Main
//-----------------------------------
main {
    margin: 15px 0;
    
    @include desktop {
        margin: 20px 0;
    }
}

//-----------------------------------
// Offer banner
//-----------------------------------
.offer {
    background: url(../images/offer.png) center no-repeat;
    background-size: cover;
    width: 100%; height: 200px;
    
    @include tablet {
        height: 250px;
    }
}

//-----------------------------------
// Start widget
//-----------------------------------
.start {
    background: linear-gradient(to bottom, #fbbd36 0%,#f6aa2c 100%);
    box-shadow: inset 0px 4px 0px 0px #ffe25b;
    border-radius: 10px;
    position: relative;
    padding: 15px;
    margin-bottom: 15px;
    
    @include tablet {
        padding: 30px;
        margin-bottom: 45px;
    }
    
    @include desktop {
        padding: 30px 100px;
    }
    
    &.in-content {
        margin-top: 0;
        margin-bottom: 0;
    }
    
    h3 {
        margin-bottom: 15px;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        
        @include tablet {
            font-size: 18px;
        }
        
        @include desktop {
            font-size: 20px;
        }
    }
    
    a {
        text-decoration: none;
    }
    
    form {
        @include tablet {
            display: flex;
        }
    }
    
    .input {
        background: #fff;
        height: 54px;
        position: relative;
        border: 3px solid #e7aa1d;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 3px 0px 0px #ffd16d, inset 0px 4px 0px 0px #d7d7d7;
        
        @include tablet {
            width: calc( 100% - 220px );
            height: 65px;
            margin-right: 20px;
        }
        
        @include desktop {
            height: 85px;
        }
        
        input {
            background: transparent;
            font-family: 'UKNumberPlate';
            font-size: 24px;
            border: 0;
            outline: none;
            width: 100%;
            text-align: center;
            height: 100%;
            padding: 5px 0 0 0;
            line-height: normal;
            
            @include tablet {
                font-size: 34px;
            }
            
            @include desktop {
                font-size: 52px;
            }
            
            &::-webkit-input-placeholder {
                color: #0b0b0b;
            }
        }
    }
    
    button {
        margin-top: 15px;
        width: 100%; padding: 15px 0;
        border: 3px solid #d29200;
        border-radius: 10px;
        background: linear-gradient(to bottom, #0cc67a 0%, #07ac68 100%);
        box-shadow: 0px 3px 0px 0px rgba(255, 209, 109, 0.4), inset 0px 3px 0px 0px rgba(48, 255, 170, 0.36);
        text-transform: uppercase;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        
        @include tablet {
            width: 200px;
            margin: 0;
            border-color: #e8aa1f;
        }
    }
}

.intro {
    margin-bottom: 15px;
    
    @include tablet {
        margin-bottom: 45px;
        text-align: center;
    }
    
    h1 {
        font-size: 20px;
        font-weight: 600;
        color: #2e2e2e;
        margin-bottom: 15px;
    }
    
    p {
        font-weight: 100;
    }
}

.features {
    
    .feature {
        border: 3px solid #e5e5e5;
        border-radius: 10px;
        padding: 12px;
        box-shadow: 0px 3px 6px 0px rgba(229, 229, 229, 0.5);
    }
    
    img {
        margin-bottom: 15px;
        border-radius: 10px;
    }
    
    h3 {
        font-size: 18px;
        font-weight: 600;
        color: #08b26d;
        margin-bottom: 5px;
    }
    
    p {
        font-weight: 100;
        margin: 0;
    }
    
    > .row [class*=col] {
        margin-bottom: 15px;
    }
}

//-----------------------------------
// What our customers say
//-----------------------------------
.feedback {
    background: url(../images/site-header.png);
    padding: 45px 0;
    text-align: center;
    position: relative;
    
    @include tablet {
        padding: 60px 0;
    }
    
    @include desktop {
        padding: 90px 0;
    }
    
    &:before {
        background: linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba( 0, 0, 0, 1 ) );
        width: 100%; height: 100%;
        position: absolute;
        top: 0; left: 0;
        content: "";
    }
    
    .container {
        position: relative;
        z-index: 5;
        
        @include desktop {
            max-width: 800px;
        }
    }
    
    h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.25;
        margin-bottom: 30px;
        color: #fbbe36;
        
        @include tablet {
            font-size: 26px;
        }
        
        @include desktop {
            font-size: 32px;
        }
    }
    
    blockquote {
        font-size: 16px;
    }
    
    p {
        color: #fff;
        font-style: italic;
        font-weight: 100;
        margin-bottom: 15px;
        
        @include tablet {
            font-size: 18px;
        }
    }
    
    cite {
        display: block;
        margin-bottom: 15px;
        color: #fbbf36;
    }
    
    ul {
        margin: 0;
    }
    
    li {
        width: 8px; height: 8px;
        border-radius: 8px;
        background: #fff;
        display: inline-block;
        
        &.active {
            background: #fbbf36;
        }
    }
}

//-----------------------------------
// Footer
//-----------------------------------
.site-footer {
    text-align: center;
    
    .contact {
        background: #fbbf36;
        padding: 45px 0;
        color: #fff;
        
        .telephone {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 1;
            
            img {
                margin-right: 10px;
            }
            
            p {
                font-size: 17px;
                margin-top: 10px;
                margin-bottom: 0;
            }
            
            a {
                font-size: 30px;
                font-weight: 600;
                display: block;
                margin-top: 5px;
                color: #fff;
                text-decoration: none;
            }
        }
    }
    
    .disclaimer {
        background: #141414;
        padding: 45px 0;
        
        @include tablet {
            box-shadow: 0px 200px 0px 200px #141414;
        }
    }
    
    p.copyright {
        color: #fbbf36;
        font-size: 11px;
        font-weight: 600;
    }
    
    p.terms {
        font-size: 12px;
        max-width: 600px;
        margin: auto;
    }
}

//-----------------------------------
// Forms
//-----------------------------------
label {
	display: block;
	font-weight: 600;
	margin-bottom: 5px;
    
    &.radio {
        font-weight: 400;
        margin: 0;
    }
}

label.option {
    margin: 0;
    color: #000;
    font-weight: 400;
    
    + label.option {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid rgba(211, 211, 211, .5);
    }
    
    input {
        display: none;
    }
    
    > div {
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .option-checkbox {
        width: 30px; height: 30px;
        min-width: 30px;
        border: 1px solid #d3d3d3;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;
        
        @include tablet {
            margin-right: 15px;
        }
    }
    
    input:checked + div .option-checkbox:after {
        background: #38d616;
        top: 5px; right: 5px; bottom: 5px; left: 5px;
        position: absolute;
        display: block;
        content: "";
        border-radius: 50%;
    }
    
    .option-description {
        width: 100%;
        
        @include tablet {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    
    h4.label {
        font-weight: 700;
    }
    
    p.price {
        margin: 0;
        
        @include tablet {
            font-size: 20px;
            font-weight: 700;
        }
    }
    
    p.description {
        margin: 0;
        font-size: 13px;
    }
    
    .heading {
        display: flex;
        justify-content: space-between;
    }
}

.option-method {
    .option-description {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    img {
        height: 20px;
        display: block;
        
        @include tablet {
            height: 30px;
        }
    }
}

.form-control {
	background: #fff;
	width: 100%;
	padding: 10px;
	border: 2px solid #ebebeb;
	border-radius: 0;
	font: inherit;
    outline: none;
    line-height: normal;
}

select.form-control {
	-webkit-appearance: none;
}

.form-group {
	margin-bottom: 15px;
}

small.error {
	color: red;
}

.help-block {
    display: block;
    margin-top: 2px;
    font-style: italic;
}

.btn {
    padding: 15px 25px;
    border: 3px solid #009257;
    border-radius: 10px;
    background: linear-gradient(to bottom, #0cc67a 0%, #07ac68 100%);
    box-shadow: inset 0px 3px 0px 0px rgba(48, 255, 170, 0.36);
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    transition: all .25s ease;
    
    &:disabled {
        opacity: 0.4;
    }
}

.btn-block {
    width: 100%;
    display: block;
    text-align: center;
}

.btn-orange {
    border-color: #d88c00;
    background: linear-gradient(to bottom, #fbbf37 0%, #fba436 100%);
    box-shadow: inset 0px 3px 0px 0px rgb(251, 174, 56);
}

.btn-checkout {
    margin-bottom: 15px;
    
    @include tablet {
        margin: 0;
    }
}

.btn-large {
    font-size: 150%;
    line-height: 1.5;
    
    @include tablet {
        line-height: 1;
        font-size: 175%;
        padding: 20px 25px;
    }
}

//-----------------------------------
// Tables
//-----------------------------------
table.basket {
    margin-bottom: 30px;
    
    th {
        background: url(../images/site-header.png);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: left;
        color: #fff;
        padding: 15px;
        
        &.basket-price {
            text-align: right;
        }
        
        &.basket-remove {
            width: 30px;
        }
    }
    
    td {
        padding: 15px;
        vertical-align: middle;
        font-size: 13px;
        
        @include tablet {
            font-size: 14px;
        }
        
        &.basket-price {
            text-align: right;
        }
    }
    
    tr:nth-child(odd) td {
        background: #f1f1f1;
    }
    
    .mobile-pricing {
        font-weight: 700;
        margin-top: 5px;
    }
    
    .total td {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        background: #fbc036 !important;
        color: #000;
    }
    
    .basket-warning {
        color: red;
        font-weight: 600;
        display: block;
    }
    
    .btn-remove {
        background: #000;
        color: #fff;
        border: none;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        outline: none;
    }
    
    .btn-remove-summary {
        background: transparent;
        border: none;
        color: #000;
        font-family: 'Poppins';
        font-size: 10px;
        text-decoration: underline;
        cursor: pointer;
        outline: none;
    }
}

//-----------------------------------
// Panels
//-----------------------------------
.panel {
    margin-bottom: 30px;
    
    .panel-heading {
        padding: 15px;
        
        h3 {
            font-weight: 600;
        }
    }
    
    .panel-body {
        padding: 15px;
    }
}

.panel-default {
    .panel-heading {
        background: url(../images/site-header.png);
        color: #fff;
        text-transform: uppercase;
    }
    .panel-body {
        background: #f5f5f5;
    }
}

.panel-thin {
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(229, 229, 229, 0.5);
    
    .panel-heading {
        border-bottom: 1px solid #d3d3d3;
        color: #000;
        display: flex;
        align-items: center;
    }
    
    .step {
        background: #fbc036;
        border-radius: 20px;
        width: 40px; height: 40px;
        display: inline-block;
        line-height: 40px;
        text-align: center;
        margin-right: 15px;
        color: #000;
        font-weight: 700;
        font-size: 18px;
    }
}

//-----------------------------------
// Plate builder
//-----------------------------------
.guide {
    background: #f1f1f1;
    margin-bottom: 15px;
    padding: 15px;
    
    p {
        margin: 0;
    }
}

.christmas-alert {
    background-color: #c70000;
    padding: 15px;
    color: #fff;
    margin-bottom: 30px;
    
    p {
        margin: 0;
    }
}

.plate-option {
    
    + .plate-option {
        margin-top: 15px;
    }
    
    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    h4 {
        margin-bottom: 5px;
    }
    
    label {
        font-weight: 400;
        font-size: 12px;
    }
}

.select {
    box-shadow: inset 0px -3px 0px 0px rgba(230, 230, 230, 0.36);
    border-radius: 5px;
    border: 1px solid #c2c2c2;
    position: relative;
    transition: all .25s ease;
    
    &.disabled {
        opacity: 0.2;
    }
    
    &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #555 transparent transparent transparent;
        position: absolute;
        top: 50%; right: 15px;
        transform: translateY(-50%);
        content: "";
        pointer-events: none;
    }
    
    select {
        background: none;
        border: none;
        appearance: none;
        outline: none;
        padding: 8px 10px 6px 10px;
        width: 100%;
        font: inherit;
        
        &:disabled {
            color: #000;
        }
    }
}

p.invalid-registration {
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 700;
    color: red;
    display: none;
}

.box {
    margin: 0;
    
    + .box {
        margin-top: 10px;
    }
    
    input {
        display: none;
    }
    
    input:checked + div {
        background: #fbba36;
        border-color: #c58d1c;
        box-shadow: 0 0 5px 1px rgba(196, 141, 29, 0.4);
    }
    
    div {
        padding: 22px 10px 20px;
        text-align: center;
        line-height: 1;
        color: #000;
        border-radius: 5px;
        border: 1px solid #a1a1a1;
        position: relative;
        cursor: pointer;
    }
    
    // Borders
    &.border.none input:checked + div {
        border-width: 1px;
        border-color: #c58d1c;
    }
    &.border.border-red div {
        border-color: #ff0000;
        border-width: 2px;
    }
    &.border.border-green div {
        border-color: #00c14d;
        border-width: 2px;
    }
    &.border.border-blue div {
        border-color: #0069c1;
        border-width: 2px;
    }
    &.border.border-black div {
        border-color: #000;
        border-width: 2px;
    }
    
    // Flags
    &.badge div:before {
        width: 42px; height: 25px;
        content: "";
        position: absolute;
        top: 50%; left: 15px;
        display: block;
        transform: translateY(-50%);
    }
    
    &.badge.badge-uk div:before {
        background-image: url(../images/preview-uk.png);
    }
    &.badge.badge-eng div:before {
        background-image: url(../images/preview-eng.png);
    }
    &.badge.badge-cym div:before {
        background-image: url(../images/preview-cym.png);
    }
    &.badge.badge-sco div:before {
        background-image: url(../images/preview-sco.png);
    }
    &.badge.badge-gb div:before {
        background-image: url(../images/preview-gb.png);
    }
    &.badge.badge-gb-plain div:before {
        background-image: url(../images/preview-gb-plain.png);
    }
}

.preview {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #cfcfcf;
    
    h2 {
        color: #000;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 30px;
        line-height: 1;
    }
    
    h4 {
        margin-bottom: 5px;
        text-align: center;
        font-size: 12px;

        @media (min-width: 992px) {
            font-size: 14px;
        }
    }
    
    .plate-wrapper {
        position: relative;
        margin-bottom: 5px;

        @media (min-width: 992px) {
            margin-bottom: 15px;
        }

        img {
            max-width: 100%;
            height: auto;
            display: block;
            will-change: transform;
            filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.25))
                    drop-shadow(-1px -1px 1px rgba(0,0,0,0.15))
                    drop-shadow(1px 1px 1px rgba(0,0,0,0.15));
        }

        .text {
            font-size: 22px;
            font-weight: 700;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            color: #000;
            z-index: 999;
            display: none;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        
        &.disabled {
            img {
                opacity: 0.05;
            }
            .text {
                display: block;
            }
        }
        
    }
    
    .plate {
        line-height: 1;
        text-align: center;
        color: #000;
        font-family: 'UKNumberPlate';
        position: relative;
        box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
        border: 1px solid #c2c2c2;
        transition: all .25s ease;
        
        border-radius: 5px;
        font-size: 16vw;
        padding: 4% 0;
        
        @include tablet {
            font-size: 128px;
            padding: 3% 0;
            border-radius: 10px;
        }
        
        // Base styling
        &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: calc(100% - 4px);
            height: 50%;
            background: rgba(255,255,255,.1);
        }
        
        &.front-plate {
            background: #fff;
            margin-bottom: 30px;
        }
        
        &.rear-plate {
            background: #fcba37;
            border-color: #c58d1c;
        }
        
        span {
            transition: all .25s ease;
            opacity: 1;
        }
        
        // Border
        &.with-border {
            
            .border {
                opacity: 1;
                visibility: visible;
            }
        }
        
        .border {
            transition: all .25s ease;
            opacity: 0;
            visibility: hidden;            
            position: absolute;
            top: 5px; right: 5px; bottom: 5px; left: 5px;
            border: 3px solid #000;
            content: "";
            border-radius: 5px;
            
            @include tablet {
                border-radius: 10px;
            }
            
            &[data-slug="border-red"] {
                border-color: #ff0000;
            }
            &[data-slug="border-black"] {
                border-color: #000;
            }
            &[data-slug="border-green"] {
                border-color: #00c14d;
            }
            &[data-slug="border-blue"] {
                border-color: #0069c1;
            }
        }
        
        
        // Badge
        &.with-badge {
            padding-left: 15%;
            
            .badge {
                opacity: 1;
                visibility: visible;
            }
        }
        
        .badge {
            transition: all .25s ease;
            opacity: 0;
            visibility: hidden;
            
            width: 15%;
            background: #0069c2;
            position: absolute;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            top: -1px; left: -1px;
            bottom: -1px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-repeat: no-repeat;
            background-size: 60%;
            background-position: center;
            
            @include tablet {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            
            &[data-slug="badge-uk"] {
                background-image: url(../images/flag-uk.png);
            }
            &[data-slug="badge-eng"] {
                background-image: url(../images/flag-eng.png);
            }
            &[data-slug="badge-cym"] {
                background-image: url(../images/flag-cym.png);
            }
            &[data-slug="badge-sco"] {
                background-image: url(../images/flag-sco.png);
            }
            &[data-slug="badge-gb"] {
                background-image: url(../images/flag-gb.png);
            }
        }
        
        // Badge & border
        &.with-badge .border {
            left: calc( 15% + 5px );
        }
        
        // Font
        &.with-font {
            &[data-font="font-3d"] span {
                text-shadow: 4px 4px 0 #888;    
            }
            // &[data-font="font-carbon"] span {
            //     background: linear-gradient(90deg, #000000 4px, transparent 1%) center, linear-gradient(#000000 4px, transparent 1%) center, #515151;
            //     -webkit-background-clip: text;
            //     -webkit-text-fill-color: transparent;
            //     background-size: 7px 7px;
            // }
        }
    }
}

.totals {    
    .total-price {
        color: #000;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        
        @include tablet {
            text-align: left;
        }
        
        span {
            font-size: 34px;
            
            @include tablet {
                display: block;
                margin-top: 6px;
            }
        }
    }
    
    .btn-add {
        width: 100%;
        display: block;
        margin-top: 30px;
        
        @include tablet {
            width: auto;
            margin-top: 0;
            float: right;
        }
    }
}

.sticky {
    position: sticky;
    top: 30px;
}

.add-to-basket {
    display: flex;
    align-items: center;
    
    p {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: #07b06b;
        margin-bottom: 0;
        margin-left: 20px;
        opacity: 0;
        visibility: hidden;
        transition: all .25s ease;
        
        &.added {
            opacity: 1;
            visibility: visible;
        }
    }
}

.accessory-image {
    margin: auto;
}

.identity-section {
    background: #fff;
    padding: 15px;
}

@media (max-height: 700px) and (max-width: 580px) {
    
    .site-header .lower {
        padding: 15px 0;
    }
    .site-header .logo img {
        max-width: 80%;
        margin: auto;
    }
    .offer {
        height: 100px;
    }
}

.reviews-widget {
    margin-bottom: 30px;
    
    #carousel-inline-widget-810 {
        max-width: 100% !important;
    }
}

.reviews-badge {
    margin: auto;
    width: 200px;
    
    @include tablet {
        float: right;
        margin-left: 25px;
    }
}

.ev-options {
    .step {
        background: #009935;
        color: #fff;
    }

    .panel-title {
        color: #009935;
    }

    p.ev-notice {
        font-size: 11px;
        margin-top: 15px;
        margin-bottom: 0;
    }
}

//-----------------------------------
// Identification Page
//-----------------------------------

.doc-title {
    background: #eee;
    padding: 10px;

    h2 {
        margin: 0;
    }
}



.alert-docs {
    background: #09c579;
    color: #fff;
    
    a {
        font-weight: 700;
        color: #fff;
    }
}

ul.docs {
    list-style: none;
    font-weight: 700;

    .align-center {
        
        @include tablet {
            display: flex;
            align-items: center;
        }
        
    }

    li {
        padding-bottom: 15px;
        border-bottom: 1px solid #ccc;
        
        @include tablet {
            border: 0;
        }
        
        @include desktop {
            border-bottom: 1px solid #ccc;
        }
        
        img {
            width: 100px;
            height: auto;
            
            @include tablet {
                width: 100%;
            }
        }

        & + li {
            margin-top: 15px;
        }
    }
}

//-----------------------------------
// Payment widget / stripe form
//-----------------------------------
.payment-widget {
    
    
    .form-control {
        appearance: none;
        background-color: white;
        height: 40px;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid transparent;
        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
        color: #000;
        
        &::-webkit-input-placeholder {
            color: #999;
        }
        
        &::-moz-placeholder {
            color: #999;
            opacity: 1;
        }
        
        &:focus, &.StripeElement--focus {
          box-shadow: 0 1px 3px 0 #cfd7df;
        }
        &.StripeElement--invalid {
          border-color: #fa755a;
        }
        &.StripeElement--webkit-autofill {
          background-color: #fefde5 !important;
        }
    }
    
    .card-errors {
        margin-top: 15px;
        font-size: 12px;
        line-height: 1;
        color: red;
    }
    
    .btn-success {
        margin-top: 15px;
    }
    
    p.return {
        margin-top: 15px;
        margin-bottom: 0;
        font-size: 12px;
        text-align: center;
    }
}

::-webkit-input-placeholder { opacity: 1; -webkit-transition: opacity .25s; transition: opacity .25s; }  /* Chrome <=56, Safari < 10 */
:-moz-placeholder { opacity: 1; -moz-transition: opacity .25s; transition: opacity .25s; } /* FF 4-18 */
::-moz-placeholder { opacity: 1; -moz-transition: opacity .25s; transition: opacity .25s; } /* FF 19-51 */
:-ms-input-placeholder { opacity: 1; -ms-transition: opacity .25s; transition: opacity .25s; } /* IE 10+ */
::placeholder { opacity: 1; transition: opacity .25s; } /* Modern Browsers */

*:focus::-webkit-input-placeholder { opacity: 0; } /* Chrome <=56, Safari < 10 */
*:focus:-moz-placeholder { opacity: 0; } /* FF 4-18 */
*:focus::-moz-placeholder { opacity: 0; } /* FF 19-50 */
*:focus:-ms-input-placeholder { opacity: 0; } /* IE 10+ */
*:focus::placeholder { opacity: 0; } /* Modern Browsers */

.offers {
    @include desktop {
        margin-bottom: 15px;
    }
    
    .offer-feature {
        height: 250px;
        background-size: cover;
        background-position: center;
        margin-bottom: 15px;
        
        @include desktop {
            margin-bottom: 15px;
        }
        
        @include large-desktop {
            height: 300px;
        }
    }
    
    a {
        display: flex;
        align-items: flex-end;
        width: 100%; height: 100%;
        color: #fff;
        text-decoration: none;
    }
    
    h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(0, 0, 0, .8);
        width: 100%; height: auto;
        margin: 0;
        padding: 10px 15px;
        font-size: 18px;
        
        @include desktop {
            font-size: 16px;
        }
        
        @include large-desktop {
            font-size: 22px;
        }
    }
    
    span:nth-child(2) {
        background: #ca0000;
        color: #fff;
        padding: 5px 10px;
        font-size: 14px;
        
        @include large-desktop {
            font-weight: bold;
        }
    }
}

//-----------------------------------
// Document upload
//-----------------------------------
.document {
    background: #fff;
    margin-bottom: 20px;

    .title {
        background: #000;
        padding: 15px;
        
        h4 {
            color: #fff;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
    }
    
    .body {
        background: #f1f1f1;
        padding: 15px;
    }
    
    .status {
        background: #fff;
        border: 2px solid #555;
        padding: 15px;
        margin-bottom: 15px;
        color: #000;
        font-weight: 500;
        
        &.pending {
            background: #ffcf76;
            border-color: orange;
        }
        &.awaiting, &.rejected {
            background: #ffb8b8;
            border-color: red;
        }
        &.approved {
            background: #abef88;
            border-color: #58b12b;
        }
        
        p {
            margin: 0;
        }
        
        span {
            font-weight: 100;
            font-style: italic;
        }
    }
    
    .pending-message {
        margin-bottom: 0;
        
        a {
            font-weight: 600;
        }
        
        & + .files:not(.visible) {
            border-top: 1px solid #ccc;
            padding-top: 15px;
            margin-top: 15px;
        }
    }
    
    .one-document {
        font-weight: 500;
        font-size: 12px;
        color: #000;
        line-height: 1;
        font-style: italic;
        margin-top: -5px;
    }
    
    ul.document-list {
        list-style: none;
        margin-bottom: 5px;
        font-size: 12px;
        
        @include tablet {
            font-size: 13px;
        }
        
        @include desktop {
            column-count: 2;
        }
        
        li {
            width: 100%;
            background: #fff;
            padding: 5px 12px;
            margin-bottom: 10px;
            
            @include tablet {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        
        a {
            color: #fcaa35;
            font-weight: 600;
            display: none;
            
            @include tablet {
                display: block;
            }
        }
        
        .document-name {
             display: block;
             position: relative;
             
             @include tablet {
                 max-width: 75%;
             }
        }        
    }
}

.upload {
    background: #000;
    padding: 15px;
    color: #fff;
    display: none;
    
    &.visible {
        display: block;
    }
    
    h2 {
        margin-bottom: 0;
        color: #fcc037;
    }
    
    .progress-bar {
        background: #fff;
        width: 100%; height: 25px;
        border-radius: 3px;
        margin-bottom: 15px;
        position: relative;
        box-shadow: 1px 2px 1px #0000000d;
        
        .bar {
            width: 0%;
            height: 100%;
            background: red;
            border-radius: 3px;
            background: repeating-linear-gradient(-45deg, #fbc136, #fbc136 10px, #fde1a0 10px, #fde1a0 20px);
            transition: all 0.2s linear;
        }
        
        .total-progress {
            position: absolute;
            top: 50%; left: 50%;
            transform: translateY(-50%) translateX(-50%);
            line-height: 1;
            font-weight: 700;
            color: #000;
        }
    }
}

.dz-preview {
    background: #fff;
    padding: 10px 15px;
    border: 1px dashed #000;
    margin-top: 15px;
}
.dz-details {
    display: flex;
    align-items: center;
    word-break: break-all;
}
.dz-icon {
    background: url(/images/icon-loading.svg) center no-repeat;
    background-size: contain;
    width: 15px; height: 15px;
    min-width: 15px;
    display: none;
    margin-right: 10px;
}
.dz-success-message {
    display: none;
}
.dz-error-message {
    display: none;
    color: #ec0f0f;
    font-weight: 500;
}
.dz-error {
    .dz-error-message {
        display: block;
    }
    .dz-icon {
        background-image: url(/images/icon-error.svg);
        display: block;
    }
}

.document-preview-modal {
    width: 90%;
    max-width: 500px;
}

.fancybox-content {
    padding: 15px;
}

.form {
    background: #f1f1f1;
    padding: 15px;
}

.email-hint {
    font-size: 12px;
    display: block;
    margin-top: 3px;
}


//-----------------------------------
// Checkout
//-----------------------------------
.body-checkout {
    background: #fff;
    
    @include tablet {
        background: #f1f1f1;
    }
    
    main {
        margin: 0;
    }
    
    .container {
        max-width: 900px;
    }
}

table.basket-new {
    
    th {
        text-align: left;
        padding: 0 5px 10px 5px;
        font-weight: 600;
        color: #000;
    }
    
    td {
        padding: 10px 5px;
        border-top: 1px solid #f1f1f1;
    }
    
    tr.total td {
        padding-bottom: 0;
    }
    
    tr:nth-child(1) td,
    tr:last-child td {
        border-color: #ddd;
    }
    
    strong {
        font-weight: 400;
    }
    
    .basket-price {
        text-align: right;
    }
}

.checkout {
    background: #fff;
    padding: 10px 0 15px 0;
    
    @include tablet {
        padding: 15px 30px 30px 30px;
    }
    
    .form-control {
        appearance: none;
        box-shadow: inset 0px -3px 0px 0px rgba(230, 230, 230, 0.25);
        border-radius: 5px;
        border: 1px solid #d3d3d3;
        transition: all .25s ease;
    }
    
    .select {
        border-color: #d3d3d3;
    }
    
    .panel-thin {
        background: #fff;
        
        .panel-heading {
            background: #f1f1f1;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }
        
        .step {
            width: 25px; height: 25px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
        }
    }
    
    .postcode-search {
        display: flex;
        
        .btn-find-address {
            margin-left: 15px;
            
            @include tablet {
                margin-left: 30px;
            }
        }
    }
    
    .postcode-results {
        .select {
            margin-top: 15px;
        }
    }
    
    p.postcode-cant-find {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
        
        a {
            text-decoration: underline;
        }
    }
    
    .title {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        margin-bottom: 12px;
        
        @include tablet {
            flex-direction: row;
        }
        
        h1 {
            margin: 0;
        }
        
        img {
            height: 30px;
            
            @include tablet {
                height: 40px;
            }
        }
    }
}

.btn-flat {
    font-size: inherit;
    background: #d3d3d3;
    color: #000;
    border-radius: 5px;
    font-family: 'Poppins';
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #969696;
    cursor: pointer;
    outline: none;
    
    &:disabled {
        opacity: 0.4;
    }
}

.btn-proceed {
    display: block;
    padding: 15px 10px;
    background: #38d616;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.elements-loading {
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .loader {
        width: 30px; height: 30px;
        border-color: #ccc;
        border-bottom-color: transparent;
        margin-bottom: 15px;
    }

    p {
        color: #777;
    }

    &.hidden {
        display: none;
    }
}

.checkout-error {
    color: #df1b41;
    margin-bottom: 15px;
}

.checkout-error-bold {
    background: #df1b41;
    padding: 15px;
    color: #fff;
    border-radius: 10px;
}

.checkout-submit {
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
        width: 15px; height: 15px;
        border-width: 3px;
        margin-right: 10px;

        &.hidden {
            display: none;
        }
    }
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
